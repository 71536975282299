/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import pdfMake from "pdfmake/build/pdfmake";
import { TDocumentDefinitions } from "pdfmake/interfaces";

import { formatNumber } from "~/common/util/formatters";

export interface PDFImageObject {
  image: string;
  width?: number;
  height?: number;
  absolutePosition?: { x: number; y: number };
  type?: string;
}

export interface CustomClusterFilters {
  feature: string;
  range: {
    from: number | null;
    to: number | null;
  };
}

const pdfMakeFonts = {
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
  },
};

pdfMake.fonts = pdfMakeFonts;

const extraSpace = " ";

export function createExportDocument({
  maps,
  filters,
}: {
  maps: PDFImageObject[];
  filters: CustomClusterFilters[];
}) {
  const filtersArray: string[] = [];

  formatFilters(filters, "gla") &&
    filtersArray.push(`GLA: ${formatFilters(filters, "gla")} sqft`);
  formatFilters(filters, "basement_finish") &&
    filtersArray.push(
      `Basement Finish: ${formatFilters(filters, "basement_finish")} sqft`,
    );
  formatFilters(filters, "lot_sqft") &&
    filtersArray.push(`Lot Size: ${formatFilters(filters, "lot_sqft")} sqft`);
  formatFilters(filters, "bedrooms") &&
    filtersArray.push(`Bedrooms: ${formatFilters(filters, "bedrooms")}`);
  formatFilters(filters, "bathrooms") &&
    filtersArray.push(`Baths: ${formatFilters(filters, "bathrooms")}`);
  formatFilters(filters, "year_built") &&
    filtersArray.push(`Year Built: ${formatFilters(filters, "year_built")}`);
  formatFilters(filters, "garage_spaces") &&
    filtersArray.push(
      `Garage Spaces: ${formatFilters(filters, "garage_spaces")}`,
    );

  const firstP = [
    "The appraiser used a data-driven approach to analyze and define the subject’s market area, utilizing an analytical tool powered by machine learning. This tool groups properties into clusters based on similar relationships between home features and sale prices, independent of city, zip code, or census boundaries—eliminating any potential for arbitrary biases. Using this data, the appraiser defined the geographic boundaries of the subject’s Core Submarket and applied filters to features such as GLA and lot size to ensure that only properties that directly compete are included in the dataset.",
  ];

  const secondP = {
    text: ["Feature filters applied: "],
    bold: true,
  };

  const thirdP = {
    ul: filtersArray,
  };

  const fourthP = [
    "Next, the tool trains a statistical model on the home sales in this appraiser-defined Core Submarket, and then applies this model to all surrounding home sales that lie within the specified filters. The tool controls (adjusts) for date of sale and feature differences (GLA, lot size, baths, etc) first, in order to isolate and identify any locational value differences. This process helps the appraiser identify similar competing submarkets (green) to find additional Comps, as well as any areas where location adjustments may be needed (blue or red). If a Comp from a blue or red zone is used, the appraiser can rely on the tool’s output to make a data-driven location adjustment.",
  ];

  const fifthP = ["The heat map below visually represents this data: "];

  const sixthP = {
    text: ["Map Legend"],
    bold: true,
    fontSize: 18,
    alignment: "center",
  };

  const key1 = {
    text: [
      {
        text: "Each black dot represents a home sale within the last 5 years that lies within the filters specified by the appraiser. (Note: Since the appraiser is focused on more recent sales (last 12 months), not all dots are relevant Comps for this assignment.)",
        color: "black",
        fontSize: 10,
      },
    ],
    fontSize: 10,
  };

  const keyPolygon = {
    text: [
      {
        text: "Solid black polygon = Subject’s Core Submarket",
        color: "black",
        fontSize: 10,
      },
    ],
    fontSize: 10,
  };

  const key2 = {
    text: [
      "Dark Green",
      {
        text: " = Similar location (<3%). Competing submarket. No location adjustments to Comps necessary.",
        color: "black",
      },
    ],
    fontSize: 10,
    color: "darkgreen",
  };

  const key3 = {
    text: [
      "Light Green",
      {
        text: " = Similar location (3-6%). Competing submarket. Location adjustment to Comps may or may not be necessary.",
        color: "black",
      },
    ],
    fontSize: 10,
    color: "lightgreen",
  };

  const key4 = {
    text: [
      "Blue",
      {
        text: " = Homes sell for less (-6% or more) after adjusting for feature differences. Positive location adjustments may be needed. The darker the blue, the less expensive the location is.",
        color: "black",
      },
    ],
    fontSize: 10,
    color: "blue",
  };

  const key5 = {
    text: [
      "Red",
      {
        text: " = Homes sell for more (+6% or more) after adjusting for feature differences. Negative location adjustments may be needed. The darker the red, the more expensive the location is.",
        color: "black",
      },
    ],
    fontSize: 10,
    color: "red",
  };

  const key6 = {
    text: [
      "White",
      {
        text: " = No home sales within the past 5 years that lie within the filters specified by the appraiser.",
        color: "black",
      },
    ],
    fontSize: 10,
  };

  const docDefinition: TDocumentDefinitions = {
    content: [
      {
        text: "Data-Driven Market Area Analysis",
        fontSize: 20,
        bold: true,
        alignment: "center",
        margin: [0, 0, 0, 0],
      },
      extraSpace,
      extraSpace,
      ...firstP,
      extraSpace,
      secondP,
      thirdP,
      extraSpace,
      fourthP,
      extraSpace,
      fifthP,
      ...maps,
      extraSpace,
      sixthP,
      extraSpace,
      key1,
      extraSpace,
      keyPolygon,
      extraSpace,
      key2,
      extraSpace,
      key3,
      extraSpace,
      key4,
      extraSpace,
      key5,
      extraSpace,
      key6,
      extraSpace,
    ],
  };

  pdfMake.createPdf(docDefinition).download("TrueTracts-export.pdf");
}

function formatFilters(filters: CustomClusterFilters[], feature: string) {
  const formattedFilters = filters.find((filter) => {
    return filter.feature === feature;
  });

  if (!formattedFilters) {
    return null;
  }
  if (feature === "year_built") {
    if (formattedFilters.range.from && formattedFilters.range.to) {
      return `${formattedFilters.range.from} - ${formattedFilters.range.to}`;
    }
    if (formattedFilters.range.from) {
      return `≥ ${formattedFilters.range.from}`;
    }
    if (formattedFilters.range.to) {
      return `≤ ${formattedFilters.range.to}`;
    }
  } else {
    if (formattedFilters.range.from && formattedFilters.range.to) {
      return `${formatNumber(formattedFilters.range.from)} - ${formatNumber(
        formattedFilters.range.to,
      )}`;
    }
    if (formattedFilters.range.from) {
      return `≥ ${formatNumber(formattedFilters.range.from)}`;
    }
    if (formattedFilters.range.to) {
      return `≤ ${formatNumber(formattedFilters.range.to)}`;
    }
  }
  return null;
}
