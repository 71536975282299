/* eslint-disable max-lines-per-function */
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import * as React from "react";

import { useExportState } from "~/boundaries/state/useExportState";
import { useSelectedClusters } from "~/boundaries/state/useSelectedClusters";
import { useSelectedCustomClusters } from "~/customClusters/hooks/useSelectedCustomClusters";
import { createExportDocument } from "./Pdf/createExportDocument";
import { exportElements } from "./Pdf/exportService";

const options = ["Export PDF", "Export Report"];

export default function SplitButton() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const { selectedClusters: selectedClustersState } = useSelectedClusters();
  const { setExportIsTriggered, setModalPDFIsOpen } = useExportState();
  const selectedClusters = [...selectedClustersState.value.values()];
  const selectedCustomClusters = useSelectedCustomClusters();

  const handleClick = () => {
    switch (options[selectedIndex]) {
      case "Export PDF":
        void handleGenerate();
        break;
      case "Export Report":
        break;
      default:
        setModalPDFIsOpen(true);
        break;
    }
  };

  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: React.SetStateAction<number>,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: { target: unknown }) => {
    if (
      (anchorRef.current as unknown as HTMLElement) &&
      (anchorRef.current as unknown as HTMLElement).contains(
        event.target as Node,
      )
    ) {
      return;
    }
    setOpen(false);
  };

  const handleGenerate = async () => {
    try {
      const map = await exportElements(selectedClusters.length);
      createExportDocument({
        maps: map,
        filters: selectedCustomClusters[0][1].filters,
      });
    } catch (error) {
      console.error("Error generating export:", error);
    } finally {
      setExportIsTriggered(false);
      setModalPDFIsOpen(false);
    }
  };

  return (
    <React.Fragment>
      <ButtonGroup variant="contained" ref={anchorRef}>
        <Button onClick={handleClick}>{options[selectedIndex]}</Button>
        <Button
          size="small"
          sx={{
            display: "none", // NOTE: This is a temporary fix to hide the dropdown button until we have additional export options
          }}
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 0,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 1}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
