import { Gite } from "@mui/icons-material";
import { AppBar, Box, Stack, styled, Toolbar, Typography } from "@mui/material";
import { Link, useLocation } from "@remix-run/react";
import { ClientOnly } from "remix-utils/client-only";
import UserAuthComponentSelector from "src/modules/auth/components/UserAuthComponentSelector";
import { useUser } from "src/modules/auth/hooks/useUser";

import TrueTractsHeader from "~/common/components/TrueTractsHeader";

const StyledHeader = styled(Typography)({
  fontFamily: "monospace",
  fontSize: 20,
  fontWeight: 700,
  letterSpacing: ".3rem",
  color: "inherit",
  textDecoration: "none",
  marginLeft: "1em",
});

export default function Header() {
  const { user } = useUser();
  const { pathname } = useLocation();

  return (
    <AppBar
      color="transparent"
      position="static"
      sx={{
        zIndex: "10",
        position: "relative",
        paddingX: 5,
      }}
    >
      <Toolbar disableGutters>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to="/truetracts"
          >
            <Stack direction="row">
              <Gite />
              <StyledHeader variant="h1">TrueTracts</StyledHeader>
            </Stack>
          </Link>
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <Stack direction="row" spacing={4}>
            {pathname.indexOf("/truetracts") !== -1 && user && (
              <ClientOnly>{() => <TrueTractsHeader />}</ClientOnly>
            )}

            <UserAuthComponentSelector />
          </Stack>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
